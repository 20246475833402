import { ModuleWithProviders, NgModule } from '@angular/core'
import { LocalStorageService } from './local-storage.service'

@NgModule({})
export class LocalStorageServiceModule {
  static forRoot(): ModuleWithProviders<LocalStorageServiceModule> {
    return {
      ngModule: LocalStorageServiceModule,
      providers: [LocalStorageService],
    };
  }
}
