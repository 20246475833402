import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoginRequest, LoginResponse, RequestCode, RequestCodeResponse, VerifyCode, VerifyCodeResponse, VerifyPhoneResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public requestCode(body: RequestCode): Observable<RequestCodeResponse> {
    return this._http.post<RequestCodeResponse>(`${this._url}auth/solicitarCodigo`, body);
  }

  public verifyCode(body: VerifyCode): Observable<VerifyCodeResponse> {
    return this._http.post<VerifyCodeResponse>(`${this._url}auth/verificarCodigo`, body);
  }

  public requestVerification(body: { telefono: string }): Observable<RequestCodeResponse> {
    return this._http.post<RequestCodeResponse>(`${this._url}auth/solicitarVerificacion`, body);
  }

  public verifyPhone(body: { telefono: string; codigo: string }): Observable<VerifyPhoneResponse> {
    return this._http.post<VerifyPhoneResponse>(`${this._url}auth/verificarTelefono`, body);
  }

  public login(body: LoginRequest): Observable<LoginResponse> {
    return this._http.post<LoginResponse>(`${this._url}auth/login`, body);
  }
}
