import { ModuleWithProviders, NgModule } from '@angular/core'
import { ActivityEventsService } from './activity-events.service'

@NgModule({})
export class ActivityEventsServiceModule {
  static forRoot(): ModuleWithProviders<ActivityEventsServiceModule> {
    return {
      ngModule: ActivityEventsServiceModule,
      providers: [ActivityEventsService],
    };
  }
}
