import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChargingSystemResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ChargingSystemService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public uploadFiles(form: FormData): Observable<ChargingSystemResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    };
    return this._http.post<ChargingSystemResponse>(`${this._url}sistema_de_carga`, form);
  }
}
