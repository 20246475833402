import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UserRequest, UserResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public setUser(body: UserRequest): Observable<UserResponse> {
    return this._http.post<UserResponse>(`${this._url}usuarios`, body);
  }

  public getUser(id: number) {
    return this._http.get(`${this._url}usuarios/${id}`);
  }

  public putUser(body: UserRequest, id: number): Observable<UserResponse> {
    return this._http.put<UserResponse>(`${this._url}usuarios/${id}`, body);
  }
}
