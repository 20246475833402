import { Injectable } from '@angular/core'
import Swal, { SweetAlertResult } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public showMessageSuccess(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#0D4B79',
      icon: 'success',
      title: title ? title : 'Éxito',
      text: message,
      iconColor: '#803790',
      confirmButtonText: 'Cerrar'
    });
  }

  public showMessageError(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#0D4B79',
      icon: 'error',
      title: title ? `<b>${title}</b>`: '<b>Error</b>',
      text: message,
      iconColor: '#E84A20',
      confirmButtonText: 'Cerrar'
    });
  }

  public showMessageWarning(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#0D4B79',
      icon: 'warning',
      title: title ? `<b>${title}</b>`: '<b>Advertencia</b>',
      text: message,
      iconColor: '#803790',
      confirmButtonText: 'Cerrar'
    });
  }
  public showMessage(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#0D4B79',
      icon: 'info',
      text: message,
      iconColor: '#803790',
      confirmButtonText: 'Cerrar'
    });
  }

  public showConfirmMessage(title: string, message: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#6CCE74',
      icon: 'warning',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      showCancelButton: true,
      cancelButtonColor: '#E6E6E6',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      focusCancel: true
    });
  }

  public showConfirmOption(title: string, message: string, option1: string, option2: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#0D4B79',
      cancelButtonColor: '#E6E6E6',
      icon: 'warning',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      showCancelButton: true,
      cancelButtonText: option2,
      confirmButtonText: option1,
      focusConfirm: true
    });
  }
}
