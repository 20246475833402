import { ModuleWithProviders, NgModule } from '@angular/core'
import { AmenitiesService } from './amenities.service'

@NgModule({})
export class AmenitiesServiceModule {
  static forRoot(): ModuleWithProviders<AmenitiesServiceModule> {
    return {
      ngModule: AmenitiesServiceModule,
      providers: [AmenitiesService],
    };
  }
}
