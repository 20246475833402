import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { SpinnerService } from './spinner.service'

@NgModule({
  imports: [CommonModule]
})
export class SpinnerServiceModule {
  static forRoot(): ModuleWithProviders<SpinnerServiceModule> {
    return {
      ngModule: SpinnerServiceModule,
      providers: [SpinnerService],
    };
  }
}
