import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EventTypesResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class EventTypesService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public getEventTypes(): Observable<EventTypesResponse> {
    return this._http.get<EventTypesResponse>(`${this._url}tipos_eventos`);
  }
}
