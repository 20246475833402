import { Injectable } from '@angular/core'
import { UserLoggedIn } from '@app/models/server'

const TOKEN: string = 'locacion-token';
const USER: string = 'locacion-user';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public setToken(token: string | undefined): void {
    localStorage.setItem(TOKEN, token as string);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  public setUser(user: UserLoggedIn): void {
    localStorage.setItem(USER, JSON.stringify(user));
  }

  public getUser(): string | null {
    return localStorage.getItem(USER);
  }

  public removeItems(): void {
    localStorage.removeItem(TOKEN);
  }
}
