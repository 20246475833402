import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { AppService, SpinnerService } from './services'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _responsive: BreakpointObserver,
    private readonly _spinner: NgxSpinnerService,
    private readonly _spinnerService: SpinnerService,
    private readonly _appService: AppService
  ) {
    this._spinnerService.showSpinner$.subscribe(this._showSpinner.bind(this));
  }

  private _showSpinner(state: boolean): void {
    if (state) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
  }

  public ngOnInit(): void {
    this._responsive
      .observe([
        '(max-width: 575px)',
        '(min-width: 576px)',
        '(min-width: 768px)',
        '(min-width: 992px)',
        '(min-width: 1200px)',
        '(min-width: 1400px)',
      ])
      .subscribe((state: BreakpointState): void => {
        const dimension: string = state.breakpoints['(max-width: 575px)']
          ? 'xs'
          : state.breakpoints['(min-width: 576px)'] &&
            !state.breakpoints['(min-width: 768px)']
          ? 'sm'
          : state.breakpoints['(min-width: 768px)'] &&
            !state.breakpoints['(min-width: 992px)']
          ? 'md'
          : state.breakpoints['(min-width: 992px)'] &&
            !state.breakpoints['(min-width: 1200px)']
          ? 'lg'
          : state.breakpoints['(min-width: 1200px)'] &&
            !state.breakpoints['(min-width: 1400px)']
          ? 'xl'
          : 'xxl';

        this._appService.setResponsive(dimension);
      });

    this._appService.autoAuth();
  }
}
