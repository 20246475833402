import { ModuleWithProviders, NgModule } from '@angular/core'
import { AppService } from './app.service'

@NgModule({})
export class AppServiceModule {
  static forRoot(): ModuleWithProviders<AppServiceModule> {
    return {
      ngModule: AppServiceModule,
      providers: [AppService],
    };
  }
}