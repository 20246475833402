import { ModuleWithProviders, NgModule } from '@angular/core'
import { EventTypesService } from '..'

@NgModule({})
export class EventTypesServiceModule {
  static forRoot(): ModuleWithProviders<EventTypesServiceModule> {
    return {
      ngModule: EventTypesServiceModule,
      providers: [EventTypesService],
    };
  }
}
