import { ModuleWithProviders, NgModule } from '@angular/core'
import { AlertsService } from './alerts.service'

@NgModule({})
export class AlertsServiceModule {
  static forRoot(): ModuleWithProviders<AlertsServiceModule> {
    return {
      ngModule: AlertsServiceModule,
      providers: [AlertsService],
    };
  }
}
