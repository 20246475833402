import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public showSpinner$: BehaviorSubject<boolean>;

  private _requestNumber: number;

  constructor() {
    this.showSpinner$ = new BehaviorSubject<boolean>(false);
    this._requestNumber = 0;
  }

  public handleRequest(state: string = 'minus'): void {
    this._requestNumber = (state === 'plus') ? this._requestNumber + 1 : this._requestNumber - 1;

    this.showSpinner$.next(this._requestNumber > 0);
  }
}
