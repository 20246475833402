import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AlertsService } from '@app/services'
import { catchError, Observable, throwError } from 'rxjs'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _alertService: AlertsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse): Observable<never> => {
      if (err.error?.errores) {
        let message: string = ''

        if (err.error?.errores?.length > 1) {
          err.error.errores.forEach((error: string): void => {
            message += `${error} `
          })
        } else {
          message = err.error?.errores[0]
        }
        /* let message = err.error.errors.length > 0 ? err.error.errors : err.error.errors[0] */

        this._alertService.showMessageError(message)
      }
      
      return throwError((): HttpErrorResponse => err);
    }));
  }
}
