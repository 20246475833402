import { ModuleWithProviders, NgModule } from '@angular/core'
import { LocationsService } from './locations.service'

@NgModule({})
export class LocationsServiceModule {
  static forRoot(): ModuleWithProviders<LocationsServiceModule> {
    return {
      ngModule: LocationsServiceModule,
      providers: [LocationsService],
    };
  }
}
