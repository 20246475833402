import { Injectable } from '@angular/core'
import { UserLoggedIn } from '@app/models/server'
import { BehaviorSubject } from 'rxjs'
import { LocalStorageService } from '..'

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public responsive$: BehaviorSubject<string>
  public listenEventEmitter$: BehaviorSubject<any>
  public listenEventEmitterAux$: BehaviorSubject<any>

  private _tokenTimer: any
  private _user: UserLoggedIn | null

  constructor(private _localStorageService: LocalStorageService) {
    this.responsive$ = new BehaviorSubject<string>('')
    this.listenEventEmitter$ = new BehaviorSubject<any>(null)
    this.listenEventEmitterAux$ = new BehaviorSubject<any>(null)

    this._user = null
  }

  public setResponsive(dimension: string): void {
    this.responsive$.next(dimension);
  }

  public setEventEmitter(event: any): void {
    this.listenEventEmitter$.next(event);
  }

  public getUser(): UserLoggedIn {
    return this._user as UserLoggedIn;
  }

  public setUser(user: UserLoggedIn): void {
    this._user = user;
  }

  public autoAuth(): void {
    const token: string | null = this._localStorageService.getToken();
    const user: string | null = this._localStorageService.getUser();

    if (token) {
      const tokenDecode: any = JSON.parse(atob(token.split('.')[1]));
      const expires: number = new Date(tokenDecode.exp * 1000).getTime();
      
      const timeout: number = expires - new Date().getTime();

      if (timeout > 0) {
        this.expirationTimer(timeout);
      }

      if (user) {
        this.setUser(JSON.parse(user))
      }
    }
  }

  public expirationTimer(timeout: number): void {
    this._tokenTimer = setTimeout((): void => {
      this.logout()
    }, timeout);
  }

  public logout(): void {
    this._localStorageService.removeItems();

    clearTimeout(this._tokenTimer);
  }
}
