import { ModuleWithProviders, NgModule } from '@angular/core'
import { HelpersService } from './helpers.service'

@NgModule()
export class HelpersServiceModule {
  static forRoot(): ModuleWithProviders<HelpersServiceModule> {
    return {
      ngModule: HelpersServiceModule,
      providers: [HelpersService],
    };
  }
}
