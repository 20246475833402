import { ModuleWithProviders, NgModule } from '@angular/core'
import { AreasService } from './areas.service'

@NgModule({})
export class AreasServiceModule {
  static forRoot(): ModuleWithProviders<AreasServiceModule> {
    return {
      ngModule: AreasServiceModule,
      providers: [AreasService],
    };
  }
}
