import { ModuleWithProviders, NgModule } from '@angular/core'
import { ShootingTypesService } from './shooting-types.service'

@NgModule({})
export class ShootingTypesServiceModule {
  static forRoot(): ModuleWithProviders<ShootingTypesServiceModule> {
    return {
      ngModule: ShootingTypesServiceModule,
      providers: [ShootingTypesService],
    };
  }
}
