import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocationEventRequest, LocationEventResponse, LocationShootingRequest, LocationShootingResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public createLocationForEvent(body: LocationEventRequest): Observable<LocationEventResponse> {
    return this._http.post<LocationEventResponse>(`${this._url}locaciones`, body);
  }

  public createLocationForShooting(body: LocationShootingRequest): Observable<LocationShootingResponse> {
    return this._http.post<LocationShootingResponse>(`${this._url}CrearLocacionFilmacion`, body);
  }
}
