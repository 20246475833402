import { ModuleWithProviders, NgModule } from '@angular/core'
import { ChargingSystemService } from './charging-system.service'

@NgModule({})
export class ChargingSystemServiceModule {
  static forRoot(): ModuleWithProviders<ChargingSystemServiceModule> {
    return {
      ngModule: ChargingSystemServiceModule,
      providers: [ChargingSystemService],
    };
  }
}
