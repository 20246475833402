import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AmenitiesResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AmenitiesService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl;
  }

  public getAmenities(): Observable<AmenitiesResponse> {
    return this._http.get<AmenitiesResponse>(`${this._url}amenidades`);
  }
}
