import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ColonyResponseError, ColonyResponseSuccess } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.apiUrl
  }

  public getColonies(zipCode: string): Observable<ColonyResponseSuccess[] | ColonyResponseError> {
    return this._http.get<ColonyResponseSuccess[] | ColonyResponseError>(
      `${this._url}obtenerCP/${zipCode}`
    );
  }
}
